export enum ActivityType {
  Assessment = "assessment",
  SoundTherapy = "sound-therapy"
}

export class Activity {
  id!: string;
  name!: string;
  image!: string;
  image_path!: string;
  description!: string;
  type!: ActivityType;

  constructor(props: any) {
    Object.assign(this, props);
  }

  getLink() {
    return `/app/activity/${this.id}`;
  }

}
