import TutorialComponent from "../components/TutorialComponent";

const routes = [
  {
    path: '/app/tutorial',
    component: TutorialComponent,
  }
];

export default routes;
