import routes from "../../routes/children";
import React, {useEffect, useState} from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import "./LayoutComponent.scss";
import RouteListComponent from "modules/core/common/components/RouteListComponent/RouteListComponent";
import FeedbackComponent, {FeedbackType} from "../../../core/common/components/FeedbackComponent/FeedbackComponent";
import AiService, {Action} from "modules/core/ai/services/AiService";
import ErrorComponent from "../../../core/common/components/ErrorComponent/ErrorComponent";

const aiService = AiService();

function LayoutComponent() {
  const [showFeedback, setShowFeedback] = useState(false);
  const [type, setType] = useState<FeedbackType | undefined>(undefined);

  useEffect(() => {
    const triggerName = "layoutFeedback";
    aiService.onAction(Action.Feedback, triggerName, (params: any) => { // can be type in para
      if (params.type) {
        setType(params.type);
      }
      setShowFeedback(true);
    });

    return () => { aiService.removeCallback(triggerName); }
  }, []);

  return (
    <div>
      <HeaderComponent/>
      <main className="app">
        <RouteListComponent routes={routes}/>
      </main>
      <FooterComponent/>
      {showFeedback && <FeedbackComponent type={type} onClose={() => setShowFeedback(false)} />}
      <div className={"app-feedback-btn"}>
        <button className={"btn"} onClick={() => setShowFeedback(!showFeedback)}>Leave feedback</button>
      </div>
      <ErrorComponent />
    </div>
  );
}

export default LayoutComponent;
