export class JourneyFilter {
  has_access: boolean | undefined;
  has_completed: boolean | undefined
  can_add: boolean | undefined
  is_released?: boolean;

  constructor(props: any) {
    Object.assign(this, props);
  }
}


export class Journey {
  id!: string;
  name!: string;
  description!: string;
  url!: string;
  image!: string;
  image_path!: string;
  is_active!: boolean;

  constructor(props: any) {
    Object.assign(this, props);
  }
}
