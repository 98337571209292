import {useEffect} from "react";
import t from "modules/core/translation/services/translations";
import onboarding1 from "assets/landing/onboarding1.png";
import onboarding2 from "assets/landing/onboarding2.png";
import onboarding3 from "assets/landing/onboarding3.png";
import "./OnboardingComponent.scss";
import {useHistory, useLocation} from "react-router-dom";
import AiService, {AiState} from "modules/core/ai/services/AiService";

const aiService = AiService();

function OnboardingComponent() {

  const location = useLocation();
  const step = +(location.search.includes("?step=") ? location.search.replace("?step=", "") : 0);
  console.log('loc', location, 'step', step);
  // const [step, setStep] = useState(0);

  const history = useHistory();

  const changeStep = (newStep: number) => {
    console.log('change step', newStep);
    if (newStep >= steps.length) {
      skip();
    } else if (newStep < 0) {
      console.error("Next step should be greater or equal to 0!");
    } else {
      // setStep(newStep);
      console.log(location.pathname+`?step=${newStep}`)
      history.replace(location.pathname+`?step=${newStep}`);
    }
  };

  const callbackName = "OnboardingComponent";
  aiService.onStateChange(callbackName, (state) => {
    if (state === AiState.End) {
      changeStep(step + 1);
    }
  });

  useEffect(() => {
    aiService.startCommunication(null);
  }, [step]);
  useEffect(() => {
    return () => {
      aiService.removeCallback(callbackName);
    };
  }, []);

  const skip = () => {
    history.replace("/");
  }
  const nextClick = (e: any) => {
    e.preventDefault();
    changeStep(step + 1);
  }
  const skipClick = (e: any) => {
    e.preventDefault();
    skip();
  }

  //todo redirect if onboarded

  const steps = [
    {header: "How to interact with the AI", texts: ["WeVoice offers you a voice based experience, which means that you will be able to engage in fulfilling conversations with our empathetic AI.", "To do so you will only have to click on the microphone button and start talking, and then the AI will reply offering support and proposing the right therapies to help you deal with your feelings. It’s as simple as that!"], image: onboarding1},
    {header: "Screening", texts: ["WeVoice will periodically ask you to do some tests about your personality, your feelings and your current status. Thanks to data that you will provide, We Voice will be able to propose you the best plan and therapies based on your answers."], image: onboarding2},
    {header: "Personalized experience", texts: ["All of your conversations with the AI are going to be stored in your personal journal, where you’ll be able to review everything you shared.\n", "You will also find all  your  personal information, including your ID number and plan, in your profile."], image: onboarding3},
  ];

  return (
    <div className="onboarding">
      <div className="onboarding-skip">
        <a href="?" onClick={skipClick}>{t("skip")}</a>
      </div>
      <div className="onboarding-content">
        <div className="onboarding-step-logo"><img src={steps[step].image} alt={steps[step].header} /></div>
        <div className="onboarding-step-text">
          <h2>{steps[step].header}</h2>
          {steps[step].texts.map((text, idx) =>
            <p key={idx}>{text}</p>
          )}
        </div>
      </div>
      <div className="steps">
        {steps.map((s, idx) =>
          <div key={idx} className={idx === step ? "step active" : "step"}></div>
        )}
      </div>
      <div className="onboarding-next">
        <a href="?" onClick={nextClick}>{t("next")}</a>
      </div>
    </div>
  );
}

export default OnboardingComponent;
