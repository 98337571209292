import JourneyListComponent from "../JourneyListComponent/JourneyListComponent";
import {JourneyFilter} from "../../models/Journey";
import Pagination from "../../../../../core/common/models/Pagination";
import t from "../../../../../core/translation/services/translations";
import "./JourneyScreen.scss";

function JourneyScreen() {
  const filter = new JourneyFilter({});
  const pagination = new Pagination(1, 10);
  return (
    <div className="app-journey-screen">
      <JourneyListComponent filter={filter} pagination={pagination} header={t('Journeys')} allowStart={false} />
    </div>
  )
}

export default JourneyScreen;
