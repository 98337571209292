import t from "modules/core/translation/services/translations";
import logo from "../../../../assets/common/logo-name.svg";
import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import "./MenuComponent.scss";
import ProfileComponent from "../ProfileComponent/ProfileComponent";

function MenuComponent() {
  const [open, setOpen] = useState(false);
  const menus = [
    {link: "/", name: "Home", exact: true},
    {link: "/app/journal", name: "Journal", exact: false},
    // {link: "/app/plan/sound-therapy", name: "Sound Therapy"},
    // {link: "/app/plan/therapy", name: "Therapies"},
    {link: "/app/journeys", name: "Journeys", exact: false},
    {link: "/app/progress", name: "Progress", exact: false},
  ];

  let location = useLocation();

  return (
    <div className={"app-menu"}>
      <div className={"app-menu-wrapper"}>
        <Link className={"logo"} to="/"><img src={logo} alt={"WeVoice"} /></Link>
        <div className={"menu-items"}>
          {menus.map((link, idx) =>
            <Link key={idx} to={link.link} className={location.pathname === link.link ? "active" : ""}>{t(link.name)}</Link>
          )}
          <ProfileComponent/>
        </div>
        <div className={"burger-menu" + (open ? " open" : "")} onClick={() => setOpen(!open)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={"menu-items-mobile"+(open ? " open" : "")}>
        {menus.map((link, idx) =>
          <Link key={idx} to={link.link} className={location.pathname === link.link ? "active" : ""}>{t(link.name)}</Link>
        )}
        <ProfileComponent/>
      </div>
    </div>
  );
}

export default MenuComponent;
