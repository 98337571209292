import SoundTherapyListComponent from "../components/SoundTherapyListComponent/SoundTherapyListComponent";
import ActivityComponent from "../components/ActivityComponent/ActivityComponent";
import TherapyListComponent from "../components/TherapyListComponent/TherapyListComponent";

const routes = [
  {
    path: '/app/activity/:id',
    component: ActivityComponent
  },
  {
    path: '/app/sound-therapy',
    component: SoundTherapyListComponent
  },
  {
    path: '/app/therapy',
    component: TherapyListComponent
  }
];

export default routes;
