import "./JourneyDetailView.scss";

function JourneyDetailView() {
  return (
    <div className={"app-journey-detail-view"}>
      <h2>Coming soon, still in development!</h2>
    </div>
  );
}

export default JourneyDetailView;
