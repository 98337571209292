import "./JourneyListComponent.scss";
import React, {useEffect, useState} from "react";
import {Journey, JourneyFilter} from "../../models/Journey";
import planService from "../../services/PlanService";
import Pagination from "../../../../../core/common/models/Pagination";
import t from "../../../../../core/translation/services/translations";
import { Link } from "react-router-dom";

export interface JourneyComponentProps {
  filter: JourneyFilter;
  pagination: Pagination | null;
  header: string;
  allowStart: boolean;
  onStart?: (() => void);
}

function JourneyListComponent(props: JourneyComponentProps) {
  const [journeys, setJourneys] = useState<Journey[]>([]);
  useEffect(() => {
    planService.getJourneys(props.filter, props.pagination).then(setJourneys);
  }, [props]);

  const start = (journey: Journey) => {
    planService.addJourney(journey.id).then(() => {
      props.onStart && props.onStart();
      console.log('Added journey');
    });
  }

  return (
    <div className={"app-journeys-component"}>
      <h2>{props.header}</h2>
      <div className="app-journey-list">
        {journeys.map((journey) =>
          <div className="app-journey-item" key={journey.id}>
            <h4>{journey.name}</h4>
            <div className="journey-description">
              <img src={journey.image_path} alt={journey.name}/>
              <div className="journey-description-text">
                <p>{journey.description}</p>
                <div className="buttons">
                  <Link to={`/app/journey/${journey.url}`}>{t("View")}</Link>
                  {props.allowStart && <button onClick={() => start(journey)} className="sec-button" disabled={!props.allowStart}>{t('Add Journey')}</button>}
                </div>
              </div>
            </div>
          </div>
        )}
        {journeys.length === 0 &&
          <div>
              <h4>{t("No Journeys Available")}</h4>
          </div>
        }
      </div>
    </div>
  );
}

export default JourneyListComponent;
