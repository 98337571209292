import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import './App.scss';
import routes from './routes';
import WeVoiceRouter from "./components/WeVoiceRouter/WeVoiceRouter";
import gtm from "./services/gtm";
import env from "./services/env";
import RouteListComponent from "./modules/core/common/components/RouteListComponent/RouteListComponent";

function ScrollToTop() {
  let isInitialMount = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!location.hash && !isInitialMount.current) {
      window.scrollTo(0, 0);
    }
    isInitialMount.current = false;
  }, [location]);
  return null;
}

const gtmId = env.isProd() ? "GTM-5SF8PNK" : null;
if (gtmId) {
  gtm.init(gtmId);
}

function App() {
  return (
    <WeVoiceRouter>
      <ScrollToTop />
      <RouteListComponent routes={routes}/>
    </WeVoiceRouter>
  );
}

export default App;
