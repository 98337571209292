import t from "../../../../../core/translation/services/translations";
import AssessmentStepComponent from "../AssessmentStepComponent/AssessmentStepComponent";
import {useEffect, useState} from "react";
import "./AssessmentComponent.scss";
import thanksImg from "assets/app/assessment-thanks.png";
import closeImg from "assets/app/close.svg";
import { Link } from "react-router-dom";
import {Assessment, AssessmentAnswer, AssessmentAnswerForm, AssessmentQuestion} from "../../models/Assessment";
import {ActivityChildComponentProps} from "../../models/ActivityChildProps";
import AiService from "../../../../../core/ai/services/AiService";

const aiService = AiService();

function AssessmentComponent(props: ActivityChildComponentProps) {
  const assessment = props.activity as Assessment;
  const [currentStep, setCurrentStep] = useState(0);
  const [formState, setFormState] = useState<any>({});
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const collectAnswers = () => new AssessmentAnswerForm(Object.keys(formState).map(id =>
    new AssessmentAnswer(+id, formState[id])
  ))

  useEffect(() => {
    if (currentStep > 0) { //initial communication in other component todo move into param
      aiService.startCommunication(null);
    }
  }, [currentStep]);

  const submit = (e: any) => {
    e.preventDefault();
    if (currentStep + 1 >= assessment.steps.length) {
      if (props.onComplete) {
        props.onComplete(collectAnswers()).then((result) => {
          aiService.startCommunication(null)
          setSubmitted(true);
        });
      } else {
        setSubmitted(true);
      }
    } else {
      switchStep(currentStep + 1);
    }
  }

  const isActive = (step: number) => {
    return step <= currentStep;
  }
  const canSwitch = (step: number) => { //validation
    const questions = assessment.steps[step].questions;
    const stepErrors = questions.reduce((e: {[key: number]: string}, question) => {
      const value = formState.hasOwnProperty(question.id+'') ? formState[question.id] : null;
      if (!question.optional && value === null) {
        e[question.id] = "Field is mandatory";
      } else if ((question.options||[]).length > 0 && !question.options.includes(value)) {
        e[question.id] = "Wrong value";
      }
      return e;
    }, {});
    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  }
  const switchStep = (step: number) => {
    if (canSwitch(currentStep)) {
      props.onUpdate && props.onUpdate(collectAnswers()).then(() => {
        setCurrentStep(step)
      });
    }
  }

  props.onStart && props.onStart();
  const changes = (question: AssessmentQuestion, answer: string) => {
    setFormState({
      ...formState,
      [question.id||0]: answer
    });
  }

  return (
    <div className="">
      {!submitted &&
        <div className="assessment-component">
          <div className="assessment-steps-summary flex-eq-children">
            {assessment.steps.map((step, idx) =>
              <div key={step.id} className={"assessment-steps-summary-item" + (isActive(idx) ? " active" : "")}
                   onClick={() => switchStep(idx)}>
                <p>{t("Step")} {idx + 1}</p>
                <p>{step.name}</p>
              </div>
            )}
          </div>
          <div className="current-step">
              <AssessmentStepComponent step={assessment.steps[currentStep]} errors={errors} onChange={changes}/>

              <div className="buttons">
                  <button onClick={submit}>{t("Submit")}</button>
              </div>
          </div>
        </div>
      }
      {submitted &&
        <div className="assessment-done">
          <Link className="close-button" to="/"><img src={closeImg} alt={"Close"} /></Link>
          <h2>{t("Thank you for completing the screening!")}</h2>
          <p>{t("Now we can elaborate your data and propose you a personal therapy plan.")}</p>
          <p>{t("After closing this message, you’ll be redirected to the home screen where Tara, your personal AI assistant, will talk to you. Enjoy the conversation!")}</p>
          <div>
            <img src={thanksImg} alt="Thank you" />
          </div>
          <Link className="assessment-done-close" to="/">{t("Okay, I’m ready!")}</Link>
        </div>
      }
    </div>
  );
}

export default AssessmentComponent;
