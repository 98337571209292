import t from "../../../translation/services/translations";
import "./ResetComponent.scss";
import {useState} from "react";
import authService from "../../services/auth.service";
import {Link} from "react-router-dom";
import {ResetForm} from "../../models/ResetForm";

function ResetComponent() {
  const [formState, setFormState] = useState({});
  const [resetted, setResetted] = useState(false);
  const [errors, setErrors] = useState<{[name: string]: string[]}>({});

  const changeValue = (event: any) => {  //this is copy paste, move to separatre service together with validation
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormState({
      ...formState,
      [event.target.name]: value
    });
  };

  const submitForm = (event: any) => {
    console.log('submit');
    event.preventDefault();
    authService.resetPassword(formState as ResetForm)
      .then(result => {
        setResetted(true);
      })
      .catch(exception => {
        console.log("Error", exception);
        exception.errors ? setErrors(exception.errors) : setErrors({email: [t('Something went wrong!')]});
      })
  }

  return (
    <div className="reset">
      <h1>{t('Forgot your password?')}</h1>

      {!resetted &&
        <div className="reset-form">
            <form onSubmit={submitForm}>
                <div className={errors && errors.hasOwnProperty("email") ? "error input-group" : "input-group"}>
                    <input type="email" name="email" placeholder={t("Email adress*")} required onChange={changeValue}/>
                    <small>{errors && errors.hasOwnProperty("email") ? errors.email : ""}</small>
                </div>
                <div>
                    <button className="main-btn" type="submit">{t('Reset')}</button>
                </div>
            </form>
        </div>
      }
      {
        resetted &&
        <div className={"success-reset"}>
            <p>{t('We have sent you email with further instructions!')}</p>
        </div>
      }

      <p>{t('Don’t have an account yet?')} <Link to="/auth/register">{t("Sign up")}</Link></p>
    </div>
  );
}

export default ResetComponent;
