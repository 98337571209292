import {ActivityType} from "../../../activity/models/Activity";
import React, {useEffect, useState} from "react";
import {SessionFilter, UserSession} from "../../models/UserSession";
import planService from "../../services/PlanService";
import t from "../../../../../core/translation/services/translations";
import "./PlanActivityListView.scss";
import AiService from "modules/core/ai/services/AiService";
import Pagination from "../../../../../core/common/models/Pagination";
import gtm, {GaEvents} from "../../../../../../services/gtm";

export interface PlanListViewProps {
  type?: ActivityType
  not_completed?: boolean
  search?: string
  refresh?: boolean
}

function PlanListView(props: PlanListViewProps) {
  const [activities, setActivities] = useState<UserSession[]>([]);

  useEffect(() => {
    const filter = new SessionFilter(props.type, props.not_completed, props.search);
    const pagination = new Pagination(1, 3); // need to move from this component if generic
    planService.getActivities(filter, pagination).then(setActivities)
  }, [props]);


  const start = (userSession: UserSession) => {
    planService.startJourneySession(userSession.session.journey_id).then(() => {
      console.log('started session');
      gtm.sendEvent(GaEvents.SessionStart, {});
      const aiService = AiService();
      aiService.startCommunication();
    });
  }

  return (
    <div>
      <div className="app-activity-list">
        {(activities || []).map((userSession, idx) =>
          <div className="app-activity-item" key={idx}>
            <h4>{userSession.session.name}</h4>
            <div className="activity-description">
              <img src={userSession.session.image_path} alt={userSession.session.name}/>
              <div className="activity-description-text">
                <p>{userSession.session.description}</p>
                <div className="buttons">
                  <button onClick={() => {start(userSession)}} className="sec-button">{t('Start Session')}</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activities.length === 0 &&
          <p>{t("There is no activities in your list. Check suggested Journeys!")}</p>
        }
      </div>
    </div>
  );
}

export default PlanListView;
