import {Activity, ActivityType} from "../../activity/models/Activity";
import {ActivityFactory} from "../../activity/models/ActivityFactory";

export class JourneySession {
  id!: string;
  journey_id!: string;
  name?: string;
  description?: string;
  image?: string;
  image_path!: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class UserSession {
  id?: number;

  scheduled_at!: Date;
  started_at!: Date|null;
  completed_at!: Date|null;
  activities: PlanActivity[] = []
  session: JourneySession;

  constructor(props: any) {
    ["scheduled_at", "started_at", "completed_at"].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.session = new JourneySession(props.session);
    // this.activities = this.activities.map(activity => new PlanActivity(activity));
  }
}

export class PlanActivity {
  id!: string;
  scheduled_at!: Date|null;
  started_at!: Date|null;
  completed_at!: Date|null;
  activity!: Activity;

  constructor(props: any) {
    ["scheduled_at", "started_at", "completed_at"].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.activity = ActivityFactory.initObject(this.activity);
  }
}


export class SessionFilter { //todo move in more general place
  'type'?: ActivityType
  is_not_completed?: boolean;
  search?: string;

  constructor(type?: ActivityType, is_not_completed?: boolean, search?: string) {
    this.type = type;
    this.search = search;
    this.is_not_completed = is_not_completed;
  }
}
