import fetchService from "../../../../core/common/services/fetch.service";
import JournalRecord from "../models/JournalRecord";

class JournalService {

  getTutorialRecords(): Promise<JournalRecord[]> {
    return Promise.resolve([
      {question_id: "123", answer_id: "543", question_text: "Is there some", answer: "Answer 1", created_at: "2021-04-01 08:32:12"},
      {question_id: "234", answer_id: "432", question_text: "How do you feel?", answer: "I had hard day, tired and want to relax!", created_at: "2021-04-01 08:32:12"},
      {question_id: "345", answer_id: "321", question_text: "How is your day?", answer: "It was amazing day, spent time with friends and was very active whole day!", created_at: "2021-03-31 08:32:12"},
    ] as JournalRecord[]);
  }

  getJournalRecords() {
    return fetchService.fetchApi<JournalRecord[]>("/api/journal", {
      method: "GET"
    });
    //   .then(result => {
    //   return new JournalRecord(result);
    // });
  }

}

export default new JournalService();
