import "./JournalComponent.scss";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import JournalService from "../services/JournalService";
import t from "../../../../core/translation/services/translations";
import JournalRecord from "../models/JournalRecord";
import AiService, {Action} from "modules/core/ai/services/AiService";

function JournalComponent() {
  const [records, setRecords] = useState<JournalRecord[]>([]);
  const [highlight, setHighlight] = useState(false);
  const location = useLocation();
  const isTutorial = location.search.includes("tutorial=");

  useEffect(() => {
    if (isTutorial) {
      const aiService = AiService();
      aiService.onAction(Action.Highlight, "journal", () => {
        setHighlight(true);
      });
      return () => {
        aiService.removeActionCallback(Action.Highlight, "journal");
      };
    } else {
      // load real data here
      JournalService.getJournalRecords().then(setRecords);
    }
  }, [isTutorial]);


  const groupedRecords = records.reduce<any>((grouped, record) => {
    const date = record.created_at.substr(0, 10);
    if (!grouped.hasOwnProperty(date)) {
      grouped[date] = {date: date, records: []}
    }
    grouped[date].records.push(record);
    return grouped;
  }, {});

  return (
    <div className="app-journal">
      <h1>{t("Journal Section")}</h1>

      <div className={"app-journal-list" + (highlight ? " highlighted" : "")}>
        {Object.values(groupedRecords).map((group: any) =>
          <div className="app-journal-list-group" key={group.date}>
            <h5 className="app-journal-list-group-header">{group.date}</h5>
            {group.records.map((record: JournalRecord) =>
              <div className="app-journal-list-item" key={record.id}>
                <div className="app-journal-list-item-question">Question: {record.question_text}</div>
                <div className="app-journal-list-item-answer">Answer: {record.answer}</div>
              </div>
            )}
          </div>
        )}
        {records.length === 0 &&
          <div className="app-journal-list-empty">
            {t("There is no records in your journal yet!")}
          </div>
        }
      </div>
    </div>
  );
}

export default JournalComponent;
