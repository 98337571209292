import JournalComponent from "../components/JournalComponent";

const routes = [
  {
    path: '/app/journal',
    component: JournalComponent
  }
];

export default routes;
