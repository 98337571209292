import userStoreService from "./user-store.service";
import env from "../../../../services/env";
import errorService from "./error.service";

//Maybe need more flexible, but now should work without any configs
const API_URL = env.isLocal() ? "http://localhost:8000" : "";
const AI_URL = env.isLocal() ? "http://localhost:4242" : "/ai";

class FetchService {

  private bearer: string | null;

  constructor() {
    this.bearer = userStoreService.getToken();
  }

  private fetch(url: string, parameters: RequestInit, returnFullResponse = false, withAuth = true) {
    return fetch(
      url,
      {...parameters, headers: this.getHeaders(parameters.headers, withAuth), mode: 'cors'}
    ).then((response) => !returnFullResponse ? this.getDataWithStatus(response) : this.getWithStatus(response));
  }

  fetchApi<T>(url: string, parameters: RequestInit, returnFullResponse = false): Promise<T> {
    return this.fetch(API_URL + url, parameters);
  }

  fetchAi<T>(url: string, parameters: RequestInit): Promise<T> {
    return this.fetch(AI_URL + url, parameters, false, false);
  }

  private getHeaders(initHeaders: any, withAuth: boolean): HeadersInit {
    const headers = initHeaders || {};
    if (this.bearer && withAuth) {
      headers["Authorization"] = `bearer ${this.bearer}`;
    }
    return headers;
  }

  private getDataWithStatus(response: Response) {
    return response.json().then(data => {
      return this.getWithStatus(response, data);
    });
  }

  private getWithStatus(response: Response, returnData: any = null) {
    if (returnData.hasOwnProperty('errors')) {
      errorService.addError(returnData.errors);
    }
    if (response.status === 401) {
      this.clearAuthorization();
      window.location.href="/auth/login";
    }

    if (response.status >= 400 && response.status <= 600) {
      return Promise.reject(returnData || response);
    }
    return Promise.resolve(returnData || response);
  }

  setAuthorization(token: string) {
    userStoreService.setToken(token);
    this.bearer = token;
  }

  clearAuthorization() {
    userStoreService.setToken(null);
    this.bearer = null;
  }

  getAuthorization() {
    return this.bearer;
  }

  objectToPath(obj: any) {
    if (obj == null) {
      return "";
    }
    const path = Object.keys(obj).filter(k => obj[k] !== undefined).reduce((d: any, k) => {
      d[k] = obj[k]
      return d;
    }, {});
    return new URLSearchParams(path).toString();
  }
}

const fetchService = new FetchService();
export default fetchService;
