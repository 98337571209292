import {Activity} from "./Activity";

export class SoundTherapy extends Activity {
  // id?: string;
  // name?: string;
  // description?: string;
  // type?: string;
  // image?: string;
  benefits: TherapyBenefits[] = [];
  isHeadphoneNeeded = true;
  link?: string;
  file_path!: string;

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
  }

  // getLink() {
  //   return `/app/sound-therapy/${this.id}`;
  // }
}

export class TherapyBenefits {
  constructor(public name: string, public icon: string, public icon_path: string) {
  }
}

export class TherapyFilter {

  constructor(public name: string) {
  }

}
