import {AuthProvider} from "./AuthProvider";
import {SocialData} from "./SocialData";

export class RegistrationForm {

  constructor(public provider: AuthProvider, public data: EmailRegistrationData | SocialData) {
  }

}

export class EmailRegistrationData {
  constructor(
    public fullname: string,
    public email: string,
    public password: string,
    public crm_key: string | null = null
  ) {
  }
}

export class RegistrationResponse {

}
