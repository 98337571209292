import "./ProgressComponent.scss";
import t from "../../../../../core/translation/services/translations";
import {useEffect, useState} from "react";
import {SessionFilter, UserSession} from "../../../plan/models/UserSession";
import planService from "../../../plan/services/PlanService";
import WeVoiceIndexChartComponent from "../WeVoiceIndexChartComponent/WeVoiceIndexChartComponent";
import PersonStateChartComponent from "../PersonStateChartComponent/PersonStateChartComponent";
import AiService, {Action} from "../../../../../core/ai/services/AiService";

enum Highlight {
  Calender = "calender",
  State = "state",
  Trend = "trend"
}

function getMonday(date: Date) {
  var day = date.getDay(),
    diff = date.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(date.setDate(diff));
}
function getSunday(date: Date) {
  var day = date.getDay(),
    diff = date.getDate() - day + (day === 0 ? 0:7); // adjust when day is sunday
  return new Date(date.setDate(diff));
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


function ProgressComponent() {

  const today = new Date();
  today.setUTCHours(0, 0,0, 0);

  const [startDate, setStartDate] = useState(getMonday(today));
  const [endDate, setEndDate] = useState(getSunday(today));
  const [activities, setActivities] = useState<UserSession[]>([]);
  const [highlight, setHighlight] = useState<string>("");

  const changeDate = () => {
    const startDate = new Date(); //todo change it
    const endDate = new Date();
    setStartDate(startDate);
    setEndDate(endDate);
  }

  useEffect(() => {
    const filter = {is_not_completed: true} as SessionFilter;
    planService.getActivities(filter).then(setActivities);
    const aiService = AiService();
    aiService.onAction(Action.Highlight, "progress", (params) => {
      setHighlight(params['highlight']);
    });
    return () => {
      aiService.removeActionCallback(Action.Highlight, "progress");
    };
  }, []);

  //index activities
  const activityIndex: any = {};
  activities.forEach(activity => {
    if (activity.scheduled_at !== null) {
      const date = activity.scheduled_at.getDate();
      if (!activityIndex.hasOwnProperty(date)) {
        activityIndex[date] = [];
      }
      activityIndex[date].push(activity);
    }
  });

  const days = [];
  const loopDate = new Date(startDate);
  while (loopDate.getTime() - endDate.getTime() <= 0) {
    const dateCopy = new Date(loopDate);
    const dateKey = dateCopy.getDate();
    days.push({ date: dateCopy, activities: activityIndex[dateKey] || [], isToday: dateCopy.getTime() - today.getTime() === 0 });
    loopDate.setDate(loopDate.getDate() + 1);
  }

  return (
    <div className={"app-progress"}>
      <h1>{t("Here you can see your plan and progress over time!")}</h1>

      <div className={"app-progress-calender-wrapper" + (highlight === Highlight.Calender ? " highlighted" : "")}>
        <h3>{t("Calender")}</h3>
        <div className="app-progress-calender">
          <h4>
            {t(monthNames[startDate.getMonth()])}
            <span onClick={(e) => changeDate()}></span>
          </h4>
          <div className="app-progress-calender-body">
            {days.map((day: any) =>
              <div className={"app-progress-calender-day"+(day.isToday ? " calender-today" : "")} key={day.date.getDate()}>
                <h5>{day.date.getDate()} {t(daysOfTheWeek[day.date.getDay()])}</h5>
                <div className="app-progress-calender-event-list">
                  {day.activities.map((activity: UserSession, idx: number) =>
                    <div className="app-progress-calender-event-item" key={idx}>
                      <h6>{activity.scheduled_at?.getHours()}:{activity.scheduled_at?.getMinutes()}</h6>
                      <p>{activity.session.name}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="app-progress-state">
        <h3>{t("State Changes")}</h3>
        <div className="app-progress-state-list">
          <div className={"app-progress-state-trend" + (highlight === Highlight.Trend ? " highlighted" : "")}>
            <h5>{t('Changes over time')}</h5>
            <WeVoiceIndexChartComponent/>
          </div>
          <div className={"app-progress-state-current" + (highlight === Highlight.State ? " highlighted" : "")}>
            <h5>{t('Current State')}</h5>
            <PersonStateChartComponent/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ProgressComponent;
