import "./TutorialComponent.scss";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import AiService from "modules/core/ai/services/AiService";

function TutorialComponent() {
  const location = useLocation();
  const aiService = AiService();
  useEffect(() => {
    aiService.startCommunication(null);
  }, [aiService, location])

  return null;
}

export default TutorialComponent;
