import {Activity} from "../../models/Activity";
import {Link} from "react-router-dom";
import t from "../../../../../core/translation/services/translations";
import React from "react";
import "./ActivityListComponent.scss";

interface ActivityListProps {
  activities: Activity[]
}

function ActivityListComponent(props: ActivityListProps) {
  return (
    <div className="app-activity-lists">
      {(props.activities || []).map(activity =>
        <div className="app-activity-item" key={activity.id}>
          <h4>{activity.name}</h4>
          <div className="activity-description">
            <img src={activity.image_path} alt={activity.name}/>
            <div className="activity-description-text">
              <p>{activity.description}</p>
              <div className="buttons">
                <Link to={activity.getLink()} className="sec-button">{t('Start therapy')}</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivityListComponent;
