export class AiResult {
  dialog_id!: string;
  utt_id!: string;
  user_id!: string;
  response: Array<string | AiResultResponse> = [];
  active_skill!: "";

  constructor(props: any) {
    Object.assign(this, props);
    if (Array.isArray(props.response)) {
      this.response = props.response.map((r: any) => this.prepareResult(r))
    } else if (typeof props.response !== 'string') {
      this.response = [this.prepareResult(props.response)];
    } else {
      this.response = [props.response];
    }
  }

  prepareResult(data: any) {
    return new AiResultResponse(data);
  }
}

export class AiResultResponse {
  text!: string;
  url?: string;
  end?: boolean;
  voice?: string
  action?: string
  delay?: number
  long_answer?: boolean
  params?: any;
  session_end?: boolean;

  constructor(props: any) {
    Object.assign(this, props);
  }
}
