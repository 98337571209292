import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ActivityChildComponentProps} from "../../../activity/models/ActivityChildProps";
import SoundTherapyComponent from "../../../activity/components/SoundTherapyComponent/SoundTherapyComponent";
import planService from "../../services/PlanService";
import {PlanActivity} from "../../models/UserSession";
import {ActivityType} from "../../../activity/models/Activity";
import AiService from "../../../../../core/ai/services/AiService";
import VoiceAssessmentComponent from "../../../activity/components/VoiceAssessmentComponent/VoiceAssessmentComponent";

const mapping: { [key: string]: (props: ActivityChildComponentProps) => JSX.Element} = { // copy from activity, but not sure which one is needed
  [ActivityType.SoundTherapy]: SoundTherapyComponent,
  [ActivityType.Assessment]: VoiceAssessmentComponent
}

const aiService = AiService();

function PlanActivityComponent() {
  const { id } = useParams<any>();
  const [activity, setActivity] = useState<PlanActivity | null>(null);

  useEffect(() => {
    planService.getActivity(id).then((activity) => {
      setActivity(activity);
    })
  }, [id]);

  useEffect(() => {
    aiService.startCommunication(null);
  }, []);

  const onStart = () => {
    if (activity && activity.started_at === null) {
      return planService.startActivity(id).then(result => {
        if (result) {
          activity.started_at = new Date();
        }
      }); //decide what to do
    } else {
      return Promise.resolve();
    }
  };
  const onResult = (result: any) => {
    return planService.updateActivityResults(id, result).then(() => console.log('update')); //decide what to do
  }
  const onComplete = (results: any) => {
    return planService.completeActivity(id, results).then(() => {
      setTimeout(() => {
        aiService.startCommunication();//todo safe command somewhere in enum
      }, 2000); // some pause before asking questions
    }); //decide what to do
  }
  const getComponent = (activity: PlanActivity | null): ((props: ActivityChildComponentProps) => JSX.Element) | null => {
    if (activity !== null) {
      const name = activity.activity.type;
      return name && mapping.hasOwnProperty(name) ? mapping[name] : null;
    }
    return null;
  }
  const ChildComponent = getComponent(activity);

  return (
    <div>
      {activity && ChildComponent &&
      <ChildComponent activity={activity.activity} onStart={onStart} onComplete={onComplete} onUpdate={onResult} />
      }
    </div>
  );
}

export default PlanActivityComponent;
