import {Action} from "../services/AiService";

export class AiAction {
  text!: string;
  url?: string;
  end?: boolean;
  voice?: string
  action?: Action
  delay?: number
  long_answer?: boolean
  params?: any;
  session_end?: boolean;

  constructor(props: any) {
    Object.assign(this, props);
  }

}

export class Communication {
  textToSay;
  endConversation;
  params: any;

  constructor(textToSay: any, endConversation = false, params = null) {
    this.textToSay = new CommunicationText(textToSay.text, textToSay.rate, textToSay.pitch, textToSay.voice);
    this.endConversation = endConversation;
    this.params = params;
  }
}

export class CommunicationText {

  text;
  pitch;
  rate;
  voice;

  constructor(text: string, rate: number, pitch: number, voice: string | null = null) {
    this.text = text;
    this.rate = rate;
    this.pitch = pitch;
    this.voice = voice;
  }

}

export class ListenParameters {
  long_answer: boolean = false;

  constructor(params: any) {
    Object.assign(this, params);
  }
}
