import fetchService from "../../../../core/common/services/fetch.service";
import {Activity} from "../models/Activity";
import {ActivityFilter} from "../models/ActivityFilter";
import {ActivityFactory} from "../models/ActivityFactory";

class ActivityService {

  getActivity(id: string): Promise<Activity> {
    return fetchService.fetchApi<Activity>(`/api/activity/activity/${id}`, {
      method: "GET",
      headers: {}
    }).then((activity: Activity) => {
      return ActivityFactory.initObject(activity);
    });
    // return Promise.resolve(this.assessment as Assessment);
  }

  getActivities(filter: ActivityFilter | null): Promise<Activity[]> {
    const data: any = filter;
    const search = filter ? new URLSearchParams(data).toString() : "";
    return fetchService.fetchApi<Activity[]>(`/api/activity/activities?${search}`, {
      method: "GET",
      headers: {}
    }).then((activities: Activity[]) =>
      (activities || []).map(a => ActivityFactory.initObject(a))
    );
  }

  startActivity(id: string): Promise<boolean> {
    return fetchService.fetchApi(`/api/activity/activity/${id}/start`, {
      method: "POST",
      headers: {}
    });
  }

  completeActivity(id: string): Promise<boolean> {
    return fetchService.fetchApi(`/api/activity/activity/${id}/complete`, {
      method: "POST",
      headers: {}
    });
  }

}

const activityService = new ActivityService();

export default activityService;
