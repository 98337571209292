import t from "../../../translation/services/translations";
import "./RestoreComponent.scss";
import {useState} from "react";
import authService from "../../services/auth.service";
import {Link, useHistory, useParams} from "react-router-dom";
import {RestoreForm} from "../../models/RestoreForm";

function RestoreComponent() {
  const { token } = useParams<any>();
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState<{[name: string]: string[]}>({});

  const changeValue = (event: any) => {  //this is copy paste, move to separatre service together with validation
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormState({
      ...formState,
      [event.target.name]: value
    });
  };

  const history = useHistory();
  const submitForm = (event: any) => {
    event.preventDefault();
    authService.restorePassword(token, formState as RestoreForm)
      .then(result => {
        history.replace("/");
      })
      .catch(exception => {
        console.log("Error", exception);
        exception.errors ? setErrors(exception.errors) : setErrors({email: [t('Something went wrong!')]});
      })
  }

  return (
    <div className="reset">
      <h1>{t('Change your password')}</h1>

      <div className="reset-form">
          <form onSubmit={submitForm}>
              <div className={errors && errors.hasOwnProperty("password") ? "error input-group" : "input-group"}>
                  <input type="password" name="password" placeholder={t("Password*")} required onChange={changeValue} />
                  <small>{errors && errors.hasOwnProperty("password") ? errors.password : ""}</small>
              </div>
              <div>
                  <button className="main-btn" type="submit">{t('Change password')}</button>
              </div>
          </form>
      </div>

      <p>{t('Don’t have an account yet?')} <Link to="/auth/register">{t("Sign up")}</Link></p>
      <p>{t('Forgot Password?')} <Link to="/auth/reset">{t("Reset it")}</Link></p>
    </div>
  );
}

export default RestoreComponent;
