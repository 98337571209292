import t from "../../../../../core/translation/services/translations";
import "./SoundTherapyComponent.scss";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {SoundTherapy} from "../../models/SoundTherapy";
import headphones from "assets/app/headphones.svg";
import {ActivityChildComponentProps} from "../../models/ActivityChildProps";
import gtm, {GaEvents} from "../../../../../../services/gtm";

//todo separate preview from playing
function SoundTherapyComponent(props: ActivityChildComponentProps) {
  const therapy = props.activity as SoundTherapy;

  // const [isTutorial, setTutorial] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const videoEl = useRef<HTMLVideoElement | null>(null);

  const switchTutorial = () => {  };
  const endTherapy = () => {
    console.log('end therapy');
    gtm.sendEvent(GaEvents.SoundTherapyEnd, {});
    //todo ai questions here..
    props.onComplete && props.onComplete({}).then(() => {
      console.log('complete')
    });
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("start")) {
      gtm.sendEvent(GaEvents.SoundTherapyStart, {});
      props.onStart && props.onStart();
      setPlaying(true);
    } else {
      setPlaying(false)
    }
  }, [location, props]);
  useEffect(() => {
    if (isPlaying) {
      videoEl.current?.play();
    }
  }, [isPlaying])

  return (
    <div className="sound-therapy-view">
      {!isPlaying &&
        <div className="sound-therapy">
          {!props.withoutBreadcrumb &&
            <div className="breadcrumbs">
                <Link to={props.backLink || "/app/plan/sound-therapy"}>&lt; {t("Sound Therapy")}</Link>
            </div>
          }
          <h3>
            {t(therapy.name || '')}
          </h3>
          <div className="sound-therapy-info">
            <div className="sound-therapy-logo">
                <img src={therapy.image_path} alt={therapy.name} />
            </div>
            <div className="sound-therapy-text">
              {therapy && therapy.benefits && therapy.benefits.length > 0 &&
                <div className="sound-therapy-benefits">
                  <h4>{t("Benefits from doing this therapy:")}</h4>
                  <div className="sound-therapy-benefits-list">
                    {(therapy.benefits||[]).map((benefit, idx) =>
                      <div className="sound-therapy-benefit-item" key={idx}>
                        <div className="benefit-icon">
                          <img src={benefit.icon_path} alt={benefit.name} />
                        </div>
                        <span>{benefit.name}</span>
                      </div>
                    )}
                  </div>
                </div>
              }
              <div className="sound-therapy-description">
                <h4>{t("Description")}</h4>
                <p>{therapy.description}</p>
                <div className="buttons flex-eq-children">
                  {therapy.isHeadphoneNeeded &&
                  <div>
                    <img src={headphones} alt="Headphones needed"/>
                    <p>{t("This therapy requires headphones.")}</p>
                  </div>
                  }
                  <div className="therapy-buttons">
                    <a href="?" className="main-btn" onClick={() => switchTutorial()}>{t("Tutorial")}</a>
                    <Link to={location.pathname + '/start'} className="main-btn">{t("Start")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {isPlaying &&
        <div>
          <div className="breadcrumbs">
            <Link to={props.backLink || location.pathname.replace("/start", "")}>&lt; { therapy.name }</Link>
          </div>
          <video ref={videoEl} width="100%" controls onEnded={() => endTherapy()} poster={therapy.image_path}>
            <source src={therapy.file_path} type="audio/wav" />
            {t("Your browser does not support the video tag.")}
          </video>
        </div>
      }
    </div>
  );
}

export default SoundTherapyComponent;
