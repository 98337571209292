// import t from "../../../../../core/translation/services/translations";
// import AssessmentStepComponent from "../AssessmentStepComponent/AssessmentStepComponent";
import {useEffect, useState} from "react";
import "./VoiceAssessmentComponent.scss";
// import thanksImg from "assets/app/assessment-thanks.png";
// import closeImg from "assets/app/close.svg";
// import { Link } from "react-router-dom";
// import {Assessment, AssessmentAnswer, AssessmentAnswerForm, AssessmentQuestion} from "../../models/Assessment";
import {ActivityChildComponentProps} from "../../models/ActivityChildProps";
import AiService from "../../../../../core/ai/services/AiService";

const aiService = AiService();

function VoiceAssessmentComponent(props: ActivityChildComponentProps) {

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const name = "VoiceAssessmentComponent";
    aiService.onStateChange(name, (state, data) => {
      console.log("VOICEAI", state, data);
      if (data) {
        setData(data);
      }
    });
    return () => { aiService.removeCallback(name) }
  }, []);

  return (
    <div className="voice-assessment-component">
      { data &&
        <div>
          <h2>{ data.textToSay.text }</h2>
          <div className="assessment-answer-options">
          {(data.params || []).map((param: any) =>
            <p className="answer-option">{ param }</p>
          )}
          </div>
        </div>
      }
    </div>
  );
}

export default VoiceAssessmentComponent;
