import {ListenParameters} from "../models/Communication";

declare global {
  interface Window { webkitAudioContext: any; }
}

window.webkitAudioContext = window.webkitAudioContext || {};

export enum MicrophoneState {
  Silence,
  Speech,
  CloseSilence,
  CloseSpeech
}

export default class VoiceListener {

  private audioContext!: AudioContext;

  private stream: MediaStream|null = null;

  private sourceNode: MediaStreamAudioSourceNode|null = null;

  private isMediaRecordSupported: boolean;

  private mediaRecorder!: StreamRecorder;

  private voiceListener!: VoiceDetector;

  private inited = false;

  private stateChangeListener: ((state: MicrophoneState) => void) | null

  constructor(stateChangeListener: ((state: MicrophoneState) => void) | null = null) {
    this.isMediaRecordSupported = !!window.MediaRecorder;
    this.stateChangeListener = stateChangeListener;
  }

  getFirstAccess() {
    if (!this.inited) {
      this.inited = true;
      return window.navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
        stream.getTracks().forEach(track => track.stop());
      });
    }
    return Promise.resolve();
  }

  private init() {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)(); // safari need to initialize before, chrome after
    }
    return window.navigator.mediaDevices.getUserMedia({audio: true}).then(stream => { //todo error support
      this.stream = stream;
      this.sourceNode = this.audioContext.createMediaStreamSource(stream);

      if (this.isMediaRecordSupported) {
        this.mediaRecorder = new VoiceMediaRecorder(this.stream);
      } else { // safari
        this.mediaRecorder = new WavMediaRecorder(this.audioContext, this.sourceNode);
      }

      this.voiceListener = new VoiceDetector(this.audioContext, this.sourceNode, (state) => {this.silenceClose(state)});
    });
  }

  private micStateChange(state: MicrophoneState) {
    this.stateChangeListener && this.stateChangeListener(state);
  }

  private micState: MicrophoneState | null = null;
  silenceClose(state: MicrophoneState) { //bad code, need clear all states
    if (this.micState !== state) {
      this.micState = state;
      this.micStateChange(state)
    }
  }

  getSpeech(params: ListenParameters | null): Promise<Blob> {
    this.micStateChange(MicrophoneState.Silence)
    return this.init().then(() => {
      return new Promise(resolve => {
        this.startRecord(params, (silence) => {
          if (silence) {
            this.micStateChange(MicrophoneState.Silence)
            this.stopRecord((file: Blob) => {
              resolve(file);
            });
          }
        });
      });
    });
  }


  private startRecord(params: ListenParameters | null, listener: (silence: boolean) => void) { // change on promise
    const longerSpeech = !!(params && params.long_answer);
    this.voiceListener.start(listener, longerSpeech);
    this.mediaRecorder.startRecord();
  }

  private stopRecord(listener: (file: Blob) => void) { // change on promise
    this.voiceListener.stop();
    this.mediaRecorder.stopRecord(listener);
    this.stream?.getTracks().forEach(track => track.stop());
  }

}

type RecordListener = (record: Blob) => void

interface StreamRecorder {

  startRecord(): Promise<boolean>;

  stopRecord(listener: RecordListener): Promise<boolean>;

}

class VoiceMediaRecorder implements StreamRecorder {

  private mediaRecorder: MediaRecorder;

  private listener!: RecordListener;

  constructor(stream: MediaStream) {
    this.mediaRecorder = new MediaRecorder(stream); //if we need to init it each time, then make file result promise
    this.mediaRecorder.ondataavailable = (e) => {
      if (this.listener) {
        this.listener(e.data);
      }
    }
  }

  startRecord() {
    if (this.mediaRecorder.state !== 'recording') {
      this.mediaRecorder.start();
    }
    return Promise.resolve(true);
  }


  stopRecord(listener: RecordListener) {
    if (this.mediaRecorder.state === 'recording') {
      this.listener = listener;
      this.mediaRecorder.stop();
    }
    return Promise.resolve(true);
  }

}

class WavMediaRecorder implements StreamRecorder {

  private scriptProcessor: ScriptProcessorNode;

  private isRecording = false;

  private storedData: Uint8Array[] = [];

  private BYTES_PER_SAMPLE = 2;

  private sampleRate: number;

  constructor(audioContext: AudioContext, audioNode: MediaStreamAudioSourceNode) {
    this.sampleRate = audioContext.sampleRate;
    this.scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1); //todo fix
    audioNode.connect(this.scriptProcessor);
    this.scriptProcessor.connect(audioContext.destination);
    this.scriptProcessor.onaudioprocess = (e) => {
      if (this.isRecording) {
        this.storedData.push(this.transformBuffersToWav(e.inputBuffer.getChannelData(0)));
      }
    }
  }

  startRecord() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.storedData = [];
    }
    return Promise.resolve(true);
  }

  stopRecord(listener: RecordListener) {
    this.isRecording = false;
    const file = this.prepareFile(this.storedData, this.sampleRate);
    listener(file);
    return Promise.resolve(true);
  }

  private transformBuffersToWav(buffer: Float32Array) {
    const length = buffer.length
    const wavData = new Uint8Array(length * this.BYTES_PER_SAMPLE)
    for (let i = 0; i < length; i++) {
      let index = i * this.BYTES_PER_SAMPLE
      let sample = buffer[i]
      if (sample > 1) {
        sample = 1
      } else if (sample < -1) {
        sample = -1
      }
      sample = sample * 32768
      wavData[index] = sample
      wavData[index + 1] = sample >> 8
    }
    return wavData;
  }

  private prepareFile(recorded: Uint8Array[], sampleRate: number) {
    let bufferLength = recorded.length ? recorded[0].length : 0
    let length = recorded.length * bufferLength
    let wav = new Uint8Array(44 + length)

    let view = new DataView(wav.buffer)

    // RIFF identifier 'RIFF'
    view.setUint32(0, 1380533830, false)
    // file length minus RIFF identifier length and file description length
    view.setUint32(4, 36 + length, true)
    // RIFF type 'WAVE'
    view.setUint32(8, 1463899717, false)
    // format chunk identifier 'fmt '
    view.setUint32(12, 1718449184, false)
    // format chunk length
    view.setUint32(16, 16, true)
    // sample format (raw)
    view.setUint16(20, 1, true)
    // channel count
    view.setUint16(22, 1, true)
    // sample rate
    view.setUint32(24, sampleRate, true)
    // byte rate (sample rate * block align)
    view.setUint32(28, sampleRate * this.BYTES_PER_SAMPLE, true)
    // block align (channel count * bytes per sample)
    view.setUint16(32, this.BYTES_PER_SAMPLE, true)
    // bits per sample
    view.setUint16(34, 8 * this.BYTES_PER_SAMPLE, true)
    // data chunk identifier 'data'
    view.setUint32(36, 1684108385, false)
    // data chunk length
    view.setUint32(40, length, true)

    for (var i = 0; i < recorded.length; i++) {
      wav.set(recorded[i], i * bufferLength + 44)
    }
    return new Blob([wav.buffer], {type: 'audio/wav'});
  }

}

type SilenceListener = (isSilene: boolean) => void
type MicrophoneStateNotifier = (state: MicrophoneState) => void

class VoiceDetector {

  private analyser: AnalyserNode;

  private interval: any = null;

  private settings = {
    loudLevel: -55,
    loudMeasureTime: 30,
    measureLoudOnInit: false,
    listenWindowSize: 50,
    longerListenWindowSize: 120,
    silenceThresholdLevel: 0.7,
    speechThresholdLevel: 0.7, // amount of silence blocks
    measurePeriodMs: 10,
    speechWindowSize: null,
    notifierWindow: 30
  };

  private lastLevel = 0;

  private listener!: SilenceListener;
  private speechEndingNotifier!: MicrophoneStateNotifier;

  private node: MediaStreamAudioSourceNode;

  /** Window of sounds during the time to validate periods of loudness */
  private window!: any[];
  /** Threshold in window when window meant to be silence */
  private silenceThreshold!: number;
  /** Threshold in window when window meant to be speech */
  private speechThreshold!: number;
  /** Last calculated state */
  private silence: boolean = true;
  /** Reset threshold in case sound appear, for longer talks */
  private resetOnSound: boolean = false;

  constructor(audioContext: AudioContext, node: MediaStreamAudioSourceNode, speechEndingNotifier: MicrophoneStateNotifier | null = null) {
    if (speechEndingNotifier) { this.speechEndingNotifier = speechEndingNotifier;}
    this.analyser = audioContext.createAnalyser();
    this.node = node;
    this.node.connect(this.analyser);
    this.init();
  }

  start(listener: SilenceListener | null = null, longerSpeech = false) {
    if (listener) { this.listener = listener; } //todo change this, overwrites initial valye may be initial not needed not sure
    let speechLength = this.settings.listenWindowSize;
    this.resetOnSound = false;
    this.setWindowSize(this.settings.listenWindowSize);
    if (longerSpeech) {
      console.log('LONG SPEECH');
      this.resetOnSound = true;
      speechLength = this.settings.longerListenWindowSize;
    }
    this.setWindowSize(speechLength);
    this.analyser.minDecibels = -55;
    this.analyzeLoudness();
    return Promise.resolve(true);
  }

  setWindowSize(windowLength: number) {
    this.window = (new Array(windowLength)).fill(true);
    const speechWindowLength = this.settings.speechWindowSize || windowLength;
    this.silenceThreshold = Math.ceil(windowLength * this.settings.silenceThresholdLevel);
    this.speechThreshold = Math.ceil(speechWindowLength * this.settings.speechThresholdLevel);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private init() {
    this.setWindowSize(this.settings.listenWindowSize);
  }

  private updateMicrophoneState() {
    const silenceBlocks = this.getSilenceBlocksCount();
    let state = MicrophoneState.Silence;
    if (this.isCloseSwitch(silenceBlocks)) {
      state = MicrophoneState.CloseSilence;
    } else if (!this.silence) {
      state = MicrophoneState.Speech
    }
    this.speechEndingNotifier && this.speechEndingNotifier(state);
  }
  
  private isCloseSwitch(silenceBlocks: number) {
    return !this.silence && silenceBlocks > this.silenceThreshold - this.window.length * this.settings.notifierWindow / 100;
  }

  private isLastBlockSilence() {
    return !!this.window[0];
  }

  private getSilenceBlocksCount() {
    return this.window.filter(a => a).length;
  }

  private analyzeLoudness() {
    this.stop();
    const fftBins = new Float32Array(this.analyser.frequencyBinCount);

    this.interval = setInterval(() => {
      this.analyser.getFloatFrequencyData(fftBins);
      this.lastLevel = this.getLoudLevel(fftBins);
      const isSilent = this.lastLevel < this.analyser.minDecibels;

      this.window.shift();
      this.window.push(isSilent);

      // if (this.resetOnSound && !isSilent) { //allow long talks, so sounds like mm aa will prolong it!
      //   this.window = this.window.map(() => false);
      // }
      this.checkSilenceChange();//maybe can be merged
      this.updateMicrophoneState();
    }, this.settings.loudMeasureTime);
  }

  private checkSilenceChange() {
    const silenceBlocks = this.getSilenceBlocksCount();
    if (silenceBlocks > this.silenceThreshold && !this.silence) {
      this.silence = true;
      this.triggerChange(this.silence);
    } else if (silenceBlocks < this.speechThreshold && this.silence) {
      this.silence = false;
      this.triggerChange(this.silence);
    }
  }

  private isSilence() {
    return this.silence;
  }

  private getLoudLevel(frequencies: Float32Array) {
    const size = frequencies.length;
    let maxFloat = -Infinity;
    for (let i = 0; i < size; ++i) {
      if (maxFloat < frequencies[i]) {
        maxFloat = frequencies[i];
      }
    }
    return maxFloat;
  }

  private triggerChange(isSilence: boolean) {
    if (this.listener) {
      this.listener(isSilence);
    }
  }

}
