import fetchService from "../../../../core/common/services/fetch.service";
import {SessionFilter, UserSession, PlanActivity} from "../models/UserSession";
import Pagination from "../../../../core/common/models/Pagination";
import {Journey, JourneyFilter} from "../models/Journey";

class PlanService { //too move to some common module

  getJourneys(filter: JourneyFilter, pagination: Pagination | null = null) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<Journey[]>(`/api/journey/journeys?${search}&${paging}`, {
      method: "GET"
    }).then(result => {
      return result.map(data => new Journey(data));
    });
  }

  addJourney(journeyId: string) {
    return fetchService.fetchApi<UserSession>(`/api/journey/${journeyId}/add`, {
      method: "POST"
    }).then(result => {
      return new UserSession(result);
    });
  }

  startJourneySession(journeyId: string) {
    return fetchService.fetchApi<UserSession>(`/api/journey/${journeyId}/start`, {
      method: "POST"
    }).then(result => {
      return new UserSession(result);
    });
  }

  getPlan() {
    return fetchService.fetchApi<UserSession>("/api/plan/my", {
      method: "GET"
    }).then(result => {
      return new UserSession(result);
    });
  }

  getTutorialActivities(numberOfEvents: number): Promise<PlanActivity[]> {
    const now = new Date();
    const activities = [];
    for (var i = 0; i < numberOfEvents; ++i) {
      const randomDay = Math.ceil(Math.random() * 12) - 6;
      const date = new Date(now)
      date.setDate(date.getDate() + randomDay);
      activities.push({
        id: i+"",
        scheduled_at: date,
        started_at: null,
        completed_at: null,
        activity: {name: `Sound session ${i}`}
      } as PlanActivity)
    }
    return Promise.resolve(activities);
  }

  getActivities(filter: SessionFilter, pagination: Pagination | null = null) {
    const search = fetchService.objectToPath(filter);
    const paging = fetchService.objectToPath(pagination);
    return fetchService.fetchApi<UserSession[]>(`/api/journey/activities?${search}&${paging}`, {
      method: "GET"
    }).then(activities => activities.map(a => new UserSession(a)))
  }

  getActivity(id: String): Promise<PlanActivity> {
    return fetchService.fetchApi<PlanActivity>(`/api/journey/activity/${id}`, {
      method: "GET"
    }).then(data => new PlanActivity(data));
  }

  startActivity(id: string) {
    return fetchService.fetchApi(`/api/journey/activity/${id}/start`, {
      method: "POST"
    });
  }

  completeActivity(id: string, result: any) {
    return fetchService.fetchApi(`/api/journey/activity/${id}/complete`, {
      method: "POST",
      body: JSON.stringify(result)
    });
  }

  updateActivityResults(id: string, result: any) {//todo decide with type, sicne depends on activity
    return fetchService.fetchApi(`/api/plan/activity/${id}/result`, {
      method: "POST",
      body: JSON.stringify(result)
    });
  }
}

const planService = new PlanService();

export default planService;
