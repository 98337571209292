import "./LoadingComponent.scss";

export interface LoadingComponentProps {
  text: string;
  fullscreen: boolean;
  smooth: true;
}

function LoadingComponent(props: LoadingComponentProps) {
  return (
    <div className={"loading-component" + (props.fullscreen ? " fullscreen" : "")}>
      <div className="progress">
      </div>
    </div>
  );
}

export default LoadingComponent;
