import "./AiButtonComponent.scss";
import aiRoseLogo from "assets/app/rose-ai.png";
import aiBlueLogo from "assets/app/blue-ai.png";
import React, {useEffect, useState} from "react";
import AiService, {Action, AiState} from "../services/AiService";
import {MicrophoneState} from "../services/VoiceRecorder";
import t from "../../translation/services/translations";

const aiService = AiService();

function AiButtonComponent() {
  const [aiState, setAiState] = useState<AiState | null>(null);
  const [micState, setMicState] = useState<MicrophoneState | null>(null);

  const [highlight, setHighlight] = useState("");
  useEffect(() => {
      aiService.onAction(Action.Highlight, "aibutton", (params) => {
        setHighlight(params['highlight']);
        if (params['ai']) {
          let state = AiState.Talk
          switch (params['ai']) {
            case 'listen':
              state = AiState.Listen;
              break;
            case 'process':
              state = AiState.Processing;
              break;
          }
          setAiState(state);
        }
        if (params['mic']) {
          const mic = params['mic'] = "speech" ? MicrophoneState.Speech : MicrophoneState.CloseSilence;
          setMicState(mic);
        }
        setTimeout(() => { setHighlight(''); }, 1000); //todo remove this number, possible memory leak
      });
      return () => {
        setHighlight("");
        aiService.removeActionCallback(Action.Highlight, "aibutton");
      };
  }, []);

  const enableAi = (e: any) => {
    e.preventDefault();
    aiService.startCommunication(null);//new Communication({text: "Here will be long text to start communication.", rate: 1.0, pitch: 1.0})
  }
  useEffect(() => {
    const callbackName = "AiButtonComponent";
    aiService.onStateChange(callbackName, (state: AiState) => {
      setAiState(state);
    });
    aiService.onMicChange((state) => {
      console.log('Mic change ', state);
      setMicState(state);
    });

    return () => {
      aiService.removeCallback(callbackName);
    };
  }, []);

  // Nothing - rose stopped
  // AI talks - Rose Round
  // Ai processing Rose signaling
  // Person can talk - Blue
  // Person Talks - Round blue
  // Time to talk near to end - Signaling blue
  const isActive = () => {
    return aiState === AiState.Talk || micState === MicrophoneState.Speech;
  }
  const isUser = () => {
    return aiState === AiState.Listen;
  }
  const isSignaling = () => {
    return aiState === AiState.Processing; //|| micState === MicrophoneState.CloseSilence; looks terrible for the moment need to play with time
  }

  const logo = isUser() ? aiBlueLogo : aiRoseLogo;
  let text = "";
  switch (aiState) {
    case AiState.Listen:
      if (micState === MicrophoneState.Speech) {
        text = "Tara is hearing you";
      } else {
        text = "Tara is listening you";
      }
      break;
    case AiState.Processing:
      text = "Tara is processing data for communication";
      break;
    case AiState.Talk:
      text = "Tara is talking to you";
      break;
    default:
      text = "Click on the icon to interact with Tara.";
  }

  return (
    <div>
      <div className={highlight === "ai" ? "highlighted" : ""}>
        <div className={isSignaling() ? "ai-button signaling" : "ai-button"} onClick={enableAi}>
          <img className={isActive() ? "active ai-graph" : "ai-graph"} src={logo} alt="Tara" />
        </div>
      </div>
      <p>{t(text)}</p>
    </div>
  );

}

export default AiButtonComponent;
