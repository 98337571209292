import PlanListView from "../PlanListView/PlanListView";
import {ActivityType} from "../../../activity/models/Activity";
import React, {useState} from "react";
import t from "../../../../../core/translation/services/translations";

function PlanSoundTherapyComponent() {
  const [search, setSearch] = useState<string>("")

  return (
    <div className="app-therapy-list">
      <h3>
        <span>{t("Sound Therapy")}</span>
        <div className="spacer"></div>
        <div className="search">
          <input type="text" className="search-input" placeholder={t("Search...")} onChange={(e) => setSearch(e.target.value)}/>
        </div>
      </h3>
      <PlanListView type={ActivityType.SoundTherapy} search={search} />
    </div>
  );
}

export default PlanSoundTherapyComponent;
