import t from "../../../../../core/translation/services/translations";
import home from "assets/app/home.png";
import "./HomeComponent.scss";
import PlanListView from "../../../plan/components/PlanListView/PlanListView";
import AiService from "../../../../../core/ai/services/AiService";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import JourneyListComponent from "../../../plan/components/JourneyListComponent/JourneyListComponent";
import {JourneyFilter} from "../../../plan/models/Journey";
import Pagination from "../../../../../core/common/models/Pagination";
import gtm, {GaEvents} from "../../../../../../services/gtm";

function HomeComponent() {

  const location = useLocation();
  const aiService = AiService();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (location.search.includes("onboarding")) {
      gtm.sendEvent(GaEvents.SessionStart, {});
      aiService.startCommunication(null, true); //only after onboarding should be true
    }
  }, [aiService, location]);

  const pagination = new Pagination(1, 3)
  const onStartJourney = () => {
    setRefresh(!refresh);
  }

  return (
    <div className="app-home-component">

      <div className="app-journeys-dashboard">
        <div className="plan">
          <h3>{t("Plan")}</h3>
          <div className="plan-steps">
            <PlanListView not_completed={true} refresh={refresh} />
          </div>
        </div>

        <div className="horizontal-separator"></div>

        <JourneyListComponent filter={new JourneyFilter({has_access: true, can_add: true, is_released: true})} header={t("Suggested Journeys")} pagination={pagination} allowStart={true} onStart={() => onStartJourney()} />

        <div className="horizontal-separator"></div>

        <JourneyListComponent filter={new JourneyFilter({is_released: false})} header={t("Future Journeys")} pagination={pagination} allowStart={false} />

      </div>

      <div className="inspiration">
        <h3>{t("Inspiration of the Day")}</h3>
        <p>{t("Mental health is not a destination, but a process. It’s about how you drive, not where you’re going.")}</p>
        <p>{t("- Noam Sphancer")}</p>
        <div className="inspration-images">
          <img src={home} alt={""} />
        </div>
      </div>
    </div>
  );
}

export default HomeComponent;
