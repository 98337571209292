import LayoutComponent from "../components/LayoutComponent/LayoutComponent";

const routes = [
  {
    path: '/auth',
    component: LayoutComponent
  }
];

export default routes;
