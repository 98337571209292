import React from "react";
import AiButtonComponent from "../../../core/ai/components/AiButtonComponent";
import MenuComponent from "../MenuComponent/MenuComponent";
import "./HeaderComponent.scss";

function HeaderComponent() {
  return (
    <header className="app">
      <div className="ai-container">
        <AiButtonComponent/>
      </div>
      <MenuComponent/>
    </header>
  );
}

export default HeaderComponent;
