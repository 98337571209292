import React, {useState} from "react";
import t from "modules/core/translation/services/translations";
import authService from "../../../core/auth/services/auth.service";
import {Link, useHistory} from "react-router-dom";
import "./ProfileComponent.scss";
import userStoreService from "../../../core/common/services/user-store.service";

function ProfileComponent() {

  const [open, setOpen] = useState(false);
  const history = useHistory();

  const openClick = (e: any) => {
    e.preventDefault();
    setOpen(!open);
  }

  const user = userStoreService.getUser();
  const logout = (e: any) => {
    e.preventDefault();
    authService.logout().then(() => {
      history.replace("/");
    });
  }

  return (
    <div className="profile-menu">
      <a href="?" onClick={openClick}>
        {user ? user.fullname : ''}
      </a>
      <div className={open ? "menu-dropdown slide-out slided" : "menu-dropdown slide-out"}>
        <Link to="">{t("Settings")}</Link>
        <Link to="">{t("Profile")}</Link>
        <a href="?" onClick={logout}>{t("Logout")}</a>
      </div>
    </div>
  );
}

export default ProfileComponent;
