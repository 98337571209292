import "./PopupComponent.scss";
import t from "modules/core/translation/services/translations";
import {useEffect, useState} from "react";

export interface PopupCompnentProps {
  component: (props: any) => JSX.Element
  onClose: () => void,
  header: string,
  description: string,
  button?: string,
  onButtonClick?: () => Promise<boolean>
}

function PopupComponent(props: PopupCompnentProps) {
  const [appeared, setAppeared] = useState(false);

  useEffect(() => {
    console.log('popup appeared');
    setAppeared(true);
  }, []);

  const close = () => {
    console.log('popup close');
    setAppeared(false);
    props.onClose();
  }
  const clickButton = () => {
    props.onButtonClick && props.onButtonClick().then(res => {
      if (res) { close(); }
    })
  }

  console.log('popup', appeared);

  return (
    <div className={appeared ? "app-popup active" : "app-popup"}>
      <div>
        <h2>{props.header}</h2>
        <p>{props.description}</p>
        <props.component />
        <div className={"buttons"}>
          {props.button && <button className={"btn main-btn"} onClick={clickButton}>{props.button}</button>}
          <div className={"spacer"}></div>
          <button className={"btn"} onClick={() => { close() }}>{t("Close")}</button>
        </div>
      </div>
    </div>
  );
}

export default PopupComponent;
