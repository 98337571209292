import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Activity} from "../../models/Activity";
import activityService from "../../services/activity.service";
import SoundTherapyComponent from "../SoundTherapyComponent/SoundTherapyComponent";
import AssessmentComponent from "../AssessmentComponent/AssessmentComponent";
import {ActivityChildComponentProps} from "../../models/ActivityChildProps";


const mapping: { [key: string]: (props: ActivityChildComponentProps) => JSX.Element} = {
  "SoundTherapy": SoundTherapyComponent,
  "Assessment": AssessmentComponent
}

function ActivityComponent() { //todo understand what should happen  before use
  const { id } = useParams<any>();
  const [activity, setActivity] = useState<Activity | null>(null);

  useEffect(() => {
    activityService.getActivity(id).then((activity) => {
      setActivity(activity);
    })
  }, [id]);

  const onStart = () => {
    //start without plan??? need to think
    return activityService.startActivity(id).then(() => console.log('started')); //decide what to do
  };
  const onComplete = () => {
    //End without plan??? need to think
    return activityService.completeActivity(id).then(() => console.log('complete')); //decide what to do
  }
  const getComponent = (activity: Activity | null): ((props: ActivityChildComponentProps) => JSX.Element) | null => {
    if (activity !== null) {
      const name = activity.constructor.name;
      return name && mapping.hasOwnProperty(name) ? mapping[name] : null;
    }
    return null;
  }
  const ChildComponent = getComponent(activity);

  return (
    <div>
      {activity && ChildComponent &&
        <ChildComponent activity={activity} onStart={onStart} onComplete={onComplete} />
      }
    </div>
  );
}

export default ActivityComponent;
