import {Activity} from "./Activity";

export class Assessment extends Activity{
  // id!: string;
  locale?: string;
  // name?: string;
  // image?: string;
  // description?: string;
  steps: AssessmentStep[] = [];

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
  }

  // getLink() {
  //   return `/app/screening/${this.id}`;
  // }
}

export class AssessmentStep {
  id?: number;
  name?: string;
  description?: string;
  questions: AssessmentQuestion[] = [];

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class AssessmentQuestion {
  id!: number;
  question?: string;
  type?: string;
  options: any[] = [];
  optional: boolean = false;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class AssessmentAnswerForm {
  answers: AssessmentAnswer[];

  constructor(answers: AssessmentAnswer[]) {
    this.answers = answers;
  }
}

export class AssessmentAnswer {
  question_id: number;
  answer_file: Blob | null;
  answer_text: string | null;

  constructor(question_id: number, answer_text: string | null, answer_file: Blob | null = null) {
    this.question_id = question_id;
    this.answer_file = answer_file;
    this.answer_text = answer_text;
  }
}
