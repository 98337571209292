import {Assessment} from "./Assessment";
import {SoundTherapy} from "./SoundTherapy";
import {Activity, ActivityType} from "./Activity";

export class ActivityFactory {
  static initObject(props: any): Activity {
    let activity = null;
    switch (props.type) {
      case ActivityType.Assessment:
        activity = new Assessment(props)
        break;
      case ActivityType.SoundTherapy:
        activity = new SoundTherapy(props)
        break;
      default:
        activity = new Activity(props)
    }
    return activity;
  }
}
