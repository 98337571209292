import { ResponsiveLine, Serie } from '@nivo/line'
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ProgressService from "../../services/ProgressService";

function WeVoiceIndexChartComponent() {
  const [data, setData] = useState<Serie[]>([]);
  const location = useLocation();
  const isTutorial = location.search.includes("tutorial=");

  useEffect(() => {
    (isTutorial ? ProgressService.getTutorialWeVoiceIndex() : ProgressService.getTutorialWeVoiceIndex()).then(index => {
      setData([{
        "id": "Wevoice Index",
        "color": "hsl(333, 70%, 50%)",
        "data": index.map(i => ({x: i.date, y: i.value}))
      }]);
    });
  }, [isTutorial]);

  return (
    <div style={{height: '300px'}}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Date',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
}

export default WeVoiceIndexChartComponent;
