function isLocal() {
  return window.location.host.startsWith("localhost");
}
function isDev() {
  return window.location.host.startsWith("dev")
}
function isProd() {
  return window.location.host.startsWith("app.wevoice");
}
function isStage() {
  return window.location.host.startsWith("stage")
}

const env = {
  isLocal,
  isDev,
  isStage,
  isProd
}

export default env;
