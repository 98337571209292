import LoginComponent from "../components/LoginComponent/LoginComponent";
import RegistrationComponent from "../components/RegistrationComponent/RegistrationComponent";
import OnboardingComponent from "../components/OnboardingComponent/OnboardingComponent";
import userStoreService from "../../common/services/user-store.service";
import ResetComponent from "../components/ResetComponent/ResetComponent";
import RestoreComponent from "../components/RestoreComponent/RestoreComponent";

const routes = [
  {
    path: '/auth/login',
    component: LoginComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() === null
  },
  {
    path: '/auth/register',
    component: RegistrationComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() === null
  },
  {
    path: '/auth/onboarding',
    component: OnboardingComponent,
    redirect: '/auth/login',
    authCheck: () => userStoreService.getUser() !== null
  },
  {
    path: '/auth/reset',
    component: ResetComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() === null
  },
  {
    path: '/auth/restore/:token',
    component: RestoreComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() === null
  },
  {
    path: '/auth',
    component: RegistrationComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() === null
  }
];

export default routes;
