import { ResponsiveRadar } from '@nivo/radar'
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ProgressService from "../../services/ProgressService";

function PersonStateChartComponent() {
  const [data, setData] = useState<any[]>([]);
  const location = useLocation();
  const isTutorial = location.search.includes("tutorial=");

  useEffect(() => {
    (isTutorial ? ProgressService.getTutorialCurrentState() : ProgressService.getTutorialCurrentState()).then((state: any | null) => {
      if (state) {
        const mapFields = Object.keys(state).map(key => {
          return {
            "state": key,
            "value": state[key]
          }
        });
        setData(mapFields);
      }
    });
  }, [isTutorial]);
  console.log('aa', data);

  return (
    <div style={{height: '300px'}}>
      <ResponsiveRadar
        data={data}
        keys={["value"]}
        indexBy="state"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={true}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel={true}
        dotLabel="value"
        dotLabelYOffset={-12}
        colors={{ scheme: 'nivo' }}
        fillOpacity={0.25}
        blendMode="multiply"
        animate={true}
        // motionConfig="wobbly"
        isInteractive={true}
      />
    </div>
  );
}

export default PersonStateChartComponent;
