import HomeComponent from "../components/home-component/HomeComponent";

const routes = [
  {
    path: '/',
    component: HomeComponent
  }
];

export default routes;
