import homeRoutes from "../modules/home/routes";
import activityRoutes from "../modules/activity/routes";
import progressRoutes from "../modules/progress/routes";
import journalRoutes from "../modules/journal/routes";
import planRoutes from "../modules/plan/routes";
import tutorialRoutes from "../modules/tutorial/routes";

const routes = [
  ...progressRoutes,
  ...activityRoutes,
  ...journalRoutes,
  ...planRoutes,
  ...tutorialRoutes,
  ...homeRoutes,
];

export default routes;
