import logo from "../../../../../assets/common/logo-name.svg";
import React from "react";
import "./HeaderComponent.scss";

function HeaderComponent() {
  return (
    <header className="auth">
      <a href="/"><img src={logo} alt={"WeVoice"} /></a>
    </header>
  );
}

export default HeaderComponent;
