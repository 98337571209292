import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import userStoreService from "../../core/common/services/user-store.service";

const routes = [
  {
    path: '/',
    component: LayoutComponent,
    redirect: '/auth',
    authCheck: () => userStoreService.getUser() !== null
  }
];

export default routes;
