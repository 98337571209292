import "./AssessmentStepComponent.scss";
import {AssessmentQuestion, AssessmentStep} from "../../models/Assessment";

type StepProps = {
  step: AssessmentStep;
  errors: {[id: number]: string}
  onChange: (question: AssessmentQuestion, value: string) => void;
}

function AssessmentStepComponent(props: StepProps) {
  const changeValue = (question: AssessmentQuestion, value: string) => {
    props.onChange(question, value);
  }

  return (
    <div className="step-container">
      {props.step.description &&
        <div className="question-description">{props.step.description}</div>
      }

      {props.step.questions.map((question, questionIdx) =>
        <div className={"question " + (props.errors.hasOwnProperty(question.id) ? "error" : "")} key={question.id}>
          <span>{question.question}</span>

          <div className={"input-group " + question.type}>
            {question.type === "text" &&
              <input type="text" onChange={(e) => changeValue(question, e.target.value)} required />
            }

            {question.type === "number" &&
            <input type="number" onChange={(e) => changeValue(question, e.target.value)} required />
            }

            {question.type === "textarea" &&
              <textarea onChange={(e) => changeValue(question, e.target.value)} rows={5}></textarea>
            }

            {question.type === "radio" && (question.options || []).map((option, optIdx) =>
              <div key={optIdx}>
                <input name={"input-" + question.id} id={`question-${questionIdx}-${optIdx}`} type="radio" value={option} onChange={(e) => changeValue(question, e.target.value)} />
                <label htmlFor={`question-${questionIdx}-${optIdx}`}>{option}</label>
              </div>
            )}

            {question.type === "checkbox" && question.options.map((option, optIdx) =>
              <div key={optIdx}>
                <input name={"input-" + question.id} id={`question-${questionIdx}-${optIdx}`} type="checkbox" value={option} onChange={(e) => changeValue(question, e.target.value)} />
                <label htmlFor={`question-${questionIdx}-${optIdx}`}>{option}</label>
              </div>
            )}

            {question.type === "select" &&
              <select onChange={(e) => changeValue(question, e.target.value)}>
                <option>Choose from list</option>
                {question.options.map((option, optIdx) =>
                  <option value={option} key={optIdx}>{option}</option>
                )}
              </select>
            }
            <small>{props.errors && props.errors.hasOwnProperty(question.id) ? props.errors[question.id] : ""}</small>
          </div>

        </div>
      )}
    </div>
  );
}

export default AssessmentStepComponent;
