// import fetchService from "../../../../core/common/services/fetch.service";
import WeVoiceIndex from "../models/WeVoiceIndex";
import CurrentState from "../models/CurrentState";

class ProgressService {

  getTutorialWeVoiceIndex(): Promise<WeVoiceIndex[]> {
    const today = new Date();
    const todayDate = today.getDate();
    const values = [];
    for (var i = 0; i < 7; ++i) {
      const date = new Date(today);
      date.setDate(todayDate-i);
      const dateString = date.getFullYear() + "/" + date.getMonth() + "/" + date.getDate();
      values.push({
        date: dateString, value: Math.ceil(Math.random()*1000)
      });
    }
    return Promise.resolve(values.reverse() as WeVoiceIndex[]);
  }

  getWeVoiceIndex(): Promise<WeVoiceIndex[]> {
    return Promise.resolve([]);
    // return fetchService.fetchApi<JournalRecord>("/api/journal", {
    //   method: "GET"
    // });
    //   .then(result => {
    //   return new JournalRecord(result);
    // });
  }

  getTutorialCurrentState(): Promise<CurrentState | null> {
    const data = {
      happieness: Math.ceil(Math.random() * 100),
      productivity: Math.ceil(Math.random() * 100),
      focus: Math.ceil(Math.random() * 100),
      confidence: Math.ceil(Math.random() * 100),
      energy: Math.ceil(Math.random() * 100),
      sleep: Math.ceil(Math.random() * 100),
    } as CurrentState;
    return Promise.resolve(data);
  }

  getCurrentState(): Promise<CurrentState | null> {
    return Promise.resolve(null);
  }

}

export default new ProgressService();
