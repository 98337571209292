import VoiceRecorder, {MicrophoneState} from "./VoiceRecorder";
import {VoiceTeller} from "./VoiceTeller";
import {AiAction, CommunicationText, ListenParameters} from "../models/Communication";
import {AiResult, AiResultResponse} from "../models/AiResponse";
import fetchService from "../../common/services/fetch.service";
import {history} from "../../../../components/WeVoiceRouter/WeVoiceRouter";
import gtm, {GaEvents} from "../../../../services/gtm";

export enum Action {
  Highlight = "highlight",
  Feedback = "feedback"
}

export enum AiState {
  Wait,
  Listen,
  Talk,
  Processing,
  End
}

class AiService {

  private state: AiState = AiState.Wait;

  private voiceRecorder: VoiceRecorder;

  private voiceTeller: VoiceTeller;

  private voiceName = "Samantha";

  private stateChangeCallbacks: {[key: string]: ((state: AiState, data: any | null) => void)} = {};

  private micChangeCallback?: (state: MicrophoneState) => void;

  private actionCallback: {[key: string]: {[key: string]: (params: any) => void}} =
    Object.values(Action).reduce(
      (total, key: any) => {
        total[key] = {};
        return total;
      },
      {} as {[key: string]: {[key: string]: (params: any) => void}}
    );

  private history: any;

  constructor(private locale: string) { //todo move to deps to test easier
    this.voiceRecorder = new VoiceRecorder((state) => { this.changeMic(state); });
    this.voiceTeller = new VoiceTeller(this.locale, this.voiceName);
    this.history = history;
  }

  onMicChange(callback: (state: MicrophoneState) => void) {
    this.micChangeCallback = callback;
  }

  changeMic(state: MicrophoneState) {
    this.micChangeCallback && this.micChangeCallback(state);
  }

  onStateChange(name: string, callback: (state: AiState, data: any | null) => void) {
    this.stateChangeCallbacks[name] = callback;
  }

  removeCallback(name: string) {
    delete this.stateChangeCallbacks[name];
  }

  onAction(actionName: Action, triggerName: string, callback: (params: any) => void) {
    const actionCallbackElement = this.actionCallback[actionName];
    if (actionCallbackElement) {
      actionCallbackElement[triggerName] = callback;
    }
  }

  removeActionCallback(actionName: Action, triggerName: string) {
    const actionCallbackElement = this.actionCallback[actionName];
    if (actionCallbackElement) {
      delete actionCallbackElement[triggerName];
    }
  }

  runAction(name: Action, params: any) {
    console.log('Run action', name, this.actionCallback);
    const actionCallbackElement = this.actionCallback[name];
    if (actionCallbackElement) {
      Object.values(actionCallbackElement).forEach(callback => callback(params));
    }
  }

  changeState(state: AiState, data: any | null = null) {
    if (this.state !== state) {
      this.state = state;
      Object.values(this.stateChangeCallbacks).forEach(callback => callback(state, data));
    }
  }

  startCommunication(action: AiAction[]|null = null, checkInit = false) { // todo manage few communications in time
    console.log('start');
    if (this.state !== AiState.End && this.state !== AiState.Wait) {
      return Promise.reject();
    }

    const pre = checkInit ? this.voiceRecorder.getFirstAccess() : Promise.resolve();
    return pre.then(() => {
      if (action) {
        this.actAndTalkAi(action);
      } else {
        this.runCommand('');
      }
    });
  }

  startCommand(command: string) {
    if (this.state !== AiState.End && this.state !== AiState.Wait) {
      return Promise.reject();
    }

    this.runCommand(command);
  }

  private runCommand(payload: string) {
    this.changeState(AiState.Processing);
    this.sendToAgent(payload);
  }

  interruptCommunication() {
    //todo implement this
  }

  /**
   * Listens to user and returns recorded data
   */
  private listenUser(params: ListenParameters | null = null) {
    gtm.sendEvent(GaEvents.PersonTalkStart, {});
    this.changeState(AiState.Listen);
    this.voiceRecorder.getSpeech(params).then((result) => { //todo timeout for speech
      gtm.sendEvent(GaEvents.PersonTalkEnd, {});
      this.processSpeech(result);
    });
  }

  private actAndTalkAi(actions: AiAction[]) {
    gtm.sendEvent(GaEvents.AiTalkStart, {});
    this.callAiActions(actions);
    gtm.sendEvent(GaEvents.AiTalkEnd, {});
  }

  private callAiActions(actions: AiAction[]) {
    if (actions.length > 0) {
      this.changeState(AiState.Talk);

      const finishAction = (leftActions: AiAction[], error = false) => { //finishAction
        if (leftActions.length) { //more actions
          this.callAiActions(leftActions);
        } else if (action.end) {
          this.endCommunication();
        } else if (!error) {
          const params = new ListenParameters({long_answer: action.long_answer});
          console.log('listen', params);
          this.listenUser(params);
        }
      }

      const action = actions.shift()!;//checked before
      this.runAiAction(action).then(() => {
        if (action.params.hasOwnProperty('ga_event')) {
          gtm.sendEvent(action.params.ga_event, {});
        }

        if (action.url) {
          this.history.push(action.url);
        }
        finishAction(actions);
      }).catch(() => {
        finishAction(actions, true);
      });
    }
  }

  private runAiAction(action: AiAction) {
    const text = new CommunicationText(action.text, 1.0, 1.0, action.voice);
    if (action.action) {
      this.runAction(action.action, action.params);
    }
    return this.voiceTeller.sayText(text).then((finished) => {
      return new Promise(resolve => {
        if (action.delay) {
          setTimeout(() => {
            resolve(true);
          }, action.delay);
        } else {
          resolve(true);
        }
      });
    }).catch((error) => {
      console.log('Could not say something');
      this.changeState(AiState.End); // reset state not to stuck, maybe can be done better
      return Promise.reject();
    })
  }

  private processSpeech(result: Blob) {
    this.changeState(AiState.Processing);
    const reader = new FileReader();
    reader.readAsDataURL(result);
    reader.onloadend = () => {
      this.sendToAgent(reader.result)
    }
  }

  private sendToAgent(payload: string | ArrayBuffer | null) {
    gtm.sendEvent(GaEvents.AiThinkingStart, {});
    return fetchService.fetchAi("", {
      method: "POST",
      body: JSON.stringify({
        user_id: fetchService.getAuthorization() || "-1",
        payload: payload, //not sure it is a best way
        referer: window.location.href
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      gtm.sendEvent(GaEvents.AiThinkingEnd, {});
      this.processResponse(response);
    }).catch(reason => {
      gtm.sendEvent(GaEvents.AiThinkingEndProblem, {});
      console.log(`Could not send to agent because of: ${reason}`);
      this.changeState(AiState.End); //not sure it is right here, and should it happen at all
      return false;
    });
  }

  private processResponse(json: any) {
    const response = new AiResult(json);

    const communicationTexts = response.response.map((item: string | AiResultResponse) => {
      return new AiAction(item instanceof AiResultResponse ? item : {text: item});
    });
    this.actAndTalkAi(communicationTexts)
  }

  private endCommunication() {
    this.changeState(AiState.End);
  }

}

let aiService: AiService | null;
const defaultLocale = "en";

export default function getInstance(locale: string = defaultLocale){
  if (!aiService) {
    aiService = new AiService(locale)
  }
  return aiService;
}
