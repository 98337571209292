import ProgressComponent from "../components/ProgressComponent/ProgressComponent";

const routes = [
  {
    path: '/app/progress',
    component: ProgressComponent,
  }
];

export default routes;
