export class ActivityFilter {
  "type": 'assessment' | 'sound-therapy';
  name: string;


  constructor(type: "assessment" | "sound-therapy", name: string) {
    this.type = type;
    this.name = name;
  }
}
