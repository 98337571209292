import {useState} from "react";
import "./FeedbackComponent.scss";
import PopupComponent from "../PopupComponent/PopupComponent";
import t from "modules/core/translation/services/translations";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import userStoreService from "../../services/user-store.service";

export enum FeedbackType {
  Bug,
  FeatureRequest,
  Positive,
  Open
}

export interface FeedbackComponentProps {
  type?: FeedbackType,
  onClose: () => void
}

const typeLinks: {[key: number]: string} = {
  [FeedbackType.Bug]: "https://airtable.com/embed/shrANLeW2fBvep6QV?backgroundColor=green",
  [FeedbackType.FeatureRequest]: "https://airtable.com/embed/shrKxLtv5MDj1LQGh?backgroundColor=green",
  [FeedbackType.Positive]: "https://airtable.com/embed/shrUmutjMppe2EeGh?backgroundColor=green",
  [FeedbackType.Open]: "https://airtable.com/embed/shrX5I9BEg7CbCIkn?backgroundColor=green"
}

function FeedbackComponent(props: FeedbackComponentProps) {
  const [type, setType] = useState<FeedbackType | undefined>(props.type);
  const [isLoading, setLoading] = useState(false);

  const close = (): void => {
    props.onClose();
  };

  const types = [
    {type: FeedbackType.Bug, name: "Issue"},
    {type: FeedbackType.FeatureRequest, name: "Idea"},
    {type: FeedbackType.Positive, name: "Positive"},
    {type: FeedbackType.Open, name: "Open"}
  ];

  const changeType = (newType: FeedbackType) => {
    setLoading(true);
    setType(newType);
  }
  const onLoad = () => {
    if (isLoading) {
      setLoading(false);
    }
  }
  const user = userStoreService.getUser();

  return (
    <PopupComponent component={() =>
      <div className={"app-feedback-component"}>
        {type === undefined &&
          <div className={"feedback-type"}>
              <p>{t('Please choose type of feedback:')}</p>
              {types.map((currentType: any, idx) =>
                <button className={"main-btn"} key={idx} onClick={() => changeType(currentType.type)}>{t(currentType.name)}</button>
              )}
          </div>
        }
        {type !== undefined &&
            <div>
              {isLoading && <LoadingComponent text={''} fullscreen={false} smooth={true} />}
              <iframe className="airtable-embed" src={typeLinks[type]+`&prefill_Email=${user?.email}&prefill_Name=${user?.fullname}`} title={"feedback"} onLoad={onLoad}
                      frameBorder="0" width="100%" height="300px"
                      style={{"background": "transparent", "border": "1px solid #ccc", "display": (isLoading ? "none" : "block")}}></iframe>
            </div>
        }
      </div>
    } onClose={close} header={t('Leave feedback!')} description={t('')} />
  );
}

export default FeedbackComponent;
