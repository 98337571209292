import React from "react";
import routes from "../../routes/children";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import RouteListComponent from "../../../common/components/RouteListComponent/RouteListComponent";

function LayoutComponent() {

  return (
    <div className="auth-layout">
      <HeaderComponent/>
      <RouteListComponent routes={routes}/>
    </div>
  );
}

export default LayoutComponent;
