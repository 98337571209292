import authRoutes from "../modules/core/auth/routes";
import appRoutes from "../modules/app/routes";


const index = [
  ...authRoutes,
  ...appRoutes,
];

export default index;
