import {User} from "../models/User";
import {RegistrationForm, RegistrationResponse} from "../models/RegistrationForm";
import {LoginForm, LoginResponse} from "../models/LoginForm";
import fetchService from "../../common/services/fetch.service";
import userStoreService from "../../common/services/user-store.service";
import {ResetForm} from "../models/ResetForm";
import {RestoreForm} from "../models/RestoreForm";



class AuthService {


  private saveUser(user: User | null) {
    userStoreService.setUser(user);
  }

  refreshUser(): Promise<User | null> {
    return fetchService.fetchApi<User>("/api/auth/user", {
      method: "GET",
    }).then(response => {
      this.saveUser(response);
      return response;
    }).catch(() => {
      this.clearUser();
      return null;
    });
  }

  register(form: RegistrationForm): Promise<RegistrationResponse> {
    return fetchService.fetchApi("/api/auth/register", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {}
    }).then((response: any) => {
      fetchService.setAuthorization(response.token);
      this.saveUser(response.user)
      return new LoginResponse(response.user.fullname, true);
    });
  }

  private setUser(response: any) {
    fetchService.setAuthorization(response.token);
    this.saveUser(response.user)
    return new LoginResponse(response.user.fullname, true);
  }

  login(form: LoginForm): Promise<any> {
    return fetchService.fetchApi<Response>("/api/auth/login", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {},
    }).then((response: any) => {
      this.setUser(response);
    });
  }

  logout() {
    return fetchService.fetchApi<Response>("/api/auth/logout", {
      method: "POST",
      body: "",
      headers: {},
    }).then(() => {
      this.clearUser();
    });
  }

  resetPassword(form: ResetForm) {
    return fetchService.fetchApi("/api/auth/reset", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {},
    });
  }

  restorePassword(token: string, form: RestoreForm): Promise<any> {
    return fetchService.fetchApi(`/api/auth/restore?token=${token}`, {
      method: "POST",
      body: JSON.stringify(form),
      headers: {},
    }).then((response: any) => {
      this.setUser(response);
    });
  }

  private clearUser() {
    fetchService.clearAuthorization();
    this.saveUser(null);
  }
}

const authService = new AuthService();
export default authService;
