import PlanActivityComponent from "../components/PlanActivityComponent/PlanActivityComponent";
import PlanSoundTherapyComponent from "../components/PlanSoundTherapyComponent/PlanSoundTherapyComponent";
import PlanAssesmentComponent from "../components/PlanTherapyComponent/PlanAssesmentComponent";
import JourneyScreen from "../components/JourneyScreen/JourneyScreen";
import JourneyDetailView from "../components/JourneyDetailView/JourneyDetailView";

const routes = [
  {
    path: '/app/plan/activity/:id',
    component: PlanActivityComponent
  },
  {
    path: '/app/plan/sound-therapy',
    component: PlanSoundTherapyComponent
  },
  {
    path: '/app/plan/therapy',
    component: PlanAssesmentComponent
  },
  {
    path: '/app/journey/:name',
    component: JourneyDetailView
  },
  {
    path: '/app/journeys',
    component: JourneyScreen
  },
];

export default routes;
