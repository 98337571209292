import t from "../../../../../core/translation/services/translations";
import React, {useEffect, useState} from "react";
import activityService from "../../services/activity.service";
import {ActivityFilter} from "../../models/ActivityFilter";
import {Activity, ActivityType} from "../../models/Activity";
import ActivityListComponent from "../ActivityListComponent/ActivityListComponent";

function SoundTherapyListComponent() {

  const [therapies, setTherapies] = useState<Activity[]>([]);
  const [search, setSearch] = useState<string>("")
  useEffect(() => {
    const activityFilter = new ActivityFilter(ActivityType.Assessment, search);
    activityService.getActivities(activityFilter).then(setTherapies);
  }, [search]);

  return (
    <div className="app-therapy-list">
      <h3>
        <span>{t("Therapy")}</span>
        <div className="spacer"></div>
        <div className="search">
          <input type="text" className="search-input" placeholder={t("Search...")} onChange={(e) => setSearch(e.target.value)}/>
        </div>
      </h3>
      <ActivityListComponent activities={therapies} />
    </div>
  );
}

export default SoundTherapyListComponent;
