import {CommunicationText} from "../models/Communication";

export class VoiceTeller {

    private locale = "en";

    private voice: SpeechSynthesisVoice | null = null;
    private voiceName: string;
    private rate: number = 1.0;
    private pitch: number = 1.0;

    private utterance: SpeechSynthesisUtterance | null = null;

    private texts: string[] = []

    private synth: SpeechSynthesis;

    private audio: HTMLAudioElement | null = null;

    /**
     *
     * @param options to set properties, supported options
     *     - {string} voiceName to change voice name
     *     - {locale} locale to change voice name
     *
     * @throws exception in case speechSynthesis not supported
     */
    constructor(locale: string, voiceName: string) {
        this.synth = window.speechSynthesis;
        this.locale = locale;
        this.voiceName = voiceName;
        if (this.synth) {
            window.speechSynthesis.getVoices();
            this.voice = this.getVoiceByName(voiceName);
            this.utterance = new SpeechSynthesisUtterance();
        }
        // console.log('getVoice', this.voice);
    }

    /**
     * Get available voices by locale, in case locale is empty will return everything
     * @param {string|null} locale
     */
    private getVoices(locale: string) {
        const voices = this.synth.getVoices();
        return locale === null ? voices : voices.filter(voice => voice.lang.startsWith(locale));
    }

    /**
     * Set Voice by it name, if not found
     * @param {string} voiceName
     */
    private getVoiceByName(voiceName: string) {
        const localeVoices = this.getVoices(this.locale);
        const namedVoice = localeVoices.find(voice => voice.name === voiceName);
        return namedVoice ? namedVoice : localeVoices[0];
    }

    private setLocale(locale: string) {
        this.locale = locale;
    }

    /**
     * Say text to person and return Promise that resolved on speech end
     *
     * @param {CommunicationText} text
     *
     * @return Promise<boolean>
     */
    sayText(text: CommunicationText) {
        if (text.voice) {
            return this.playVoice(text.voice);
        } else if (this.synth) {
            return this.sayUtterance(text);
        } else {
            return Promise.reject(false);
        }
    }

    playVoice(voice: string): Promise<boolean> {
        return new Promise(resolve => {
            if (!this.audio) {
                this.audio = new Audio();
            }
            this.audio.src = voice;
            const handler = () => {
                resolve(true);
                if (this.audio) {
                    this.audio.removeEventListener('ended', handler);
                }
                // this.audio = null;
            }
            this.audio.addEventListener('ended', handler)
            try {
                this.audio.play();
            } catch (e) {
                console.log('error');
                resolve(false);
            }
        });
    }

    sayUtterance(text: CommunicationText): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.utterance) {
                return reject(false);
            }
            if (!this.voice) {
                this.voice = this.getVoiceByName(this.voiceName);
            }
            this.utterance.voice = this.voice;
            this.texts = text.text.split(".").filter(t => t.trim().length > 0);
            this.pitch = text.pitch;
            this.rate = text.rate;
            this.utterance.onend = () => {
                if (this.texts.length > 0) {
                    this.speakNext();
                } else {
                    resolve(true);
                }
            };
            this.utterance.onerror = (error) => {
                console.log('utt error', error);
                reject(error);
            }
            this.speakNext();
        });
    }

    speak(text: string) {
        if (!this.utterance) { return }

        this.utterance.rate = this.rate;
        this.utterance.text = text;
        this.utterance.pitch = this.pitch;
        this.synth.speak(this.utterance);
    }

    speakNext() {
        const text = this.texts.shift();
        if (text) {
            this.speak(text);
        }
    }

}
