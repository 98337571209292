import errorService from "modules/core/common/services/error.service";
import {Error} from "modules/core/common/models/error";
import {useEffect, useState} from "react";
import "./ErrorComponent.scss";

function ErrorComponent() {
  const [errors, setErrors] = useState<Error|null>(null);

  useEffect(() => {
    let timer: any|null = null;
    const key = "";
    const handler = (error: Error): void => {
      setErrors(error);
      timer = setTimeout(() => {
        setErrors(null);
      }, 5000);
    };
    errorService.onError(key, handler);
    return () => {
      errorService.removeOnError(key);
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [])

  return errors ? (
    <div className="app-error-component">
      <div>{errors.text}</div>
    </div>
  ) : null;
}

export default ErrorComponent;
